import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import {
  useConfigurationStore,
  useMobileDevicesStore,
  useHearingEvaluationStore,
  useUiStore,
} from "hooks/stores"
import { format } from "date-fns"
import { Menu, MenuItem } from "@material-ui/core"
import { handleSelectInputsOld } from "../programs_helpers"
import { DEFAULT_CONFIG_FACTORY } from "stores/configuration_store/configuration_mock_objects"
import { mapKeysToCamelCase } from "utils/helpers"
import { handleSelectInputs } from "../programs_helpers"
import { ManualIcon } from "images/svg"

const ConfigurationHistoriesWrapper = styled.div`
  max-height: 150px;
  border-top: 1px solid rgba(55, 55, 55, 0.17);
  overflow: auto;
`

const HistoryConfigItem = styled.div`
  border-bottom: 1px solid rgba(55, 55, 55, 0.17);
  padding: 16px;
  position: relative;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`

const DateWrapper = styled.div`
  display: inline-block;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  flex: 1;
  margin-right: 10px;
`

const ManualWrapper = styled.div`
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 3px;
`

const ActiveWrapper = styled.div`
  display: inline-block;
  flex: 0.3;
`

const ActionWrapper = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 25px;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: "…";
    transform: rotate(90deg);
    color: black;
    position: relative;
    font-size: 20px;
    left: calc(50% - 2px);
    top: 7px;
    display: inline-flex;
  }
`

const ControlButtons = styled.div`
  display: inline-block;
  justify-content: flex-end;
  flex: 0.1;
  position: relative;
  right: 0;
  bottom: 10px;
  height: 12px;
`

const StyledMenu = styled(Menu)`
  && {
    .MuiMenu-paper {
      min-width: 100px;
      border-radius: 4px;
      background: white;
      color: black;
    }
  }
`

const HearingEvaluationHistory = () => {
  const {
    setTempHearingAssessmentLeftBisgaardIndex,
    setTempHearingAssessmentRightBisgaardIndex,
    hearingEvaluationHistoryList,
    setPreviewHearingAssessmentLeftBisgaardIndex,
    setPreviewHearingAssessmentRightBisgaardIndex,
    setPreviewIndex,
    previewIndex,
    activeHearingAssessmentIndex,
    setEnabledConfigurationOfIndex,
    setIsNewSoundMatch,
    isNewSoundMatch,
    removeHearingEvaluation,
    setNewHearingAssessment,
    isShowingRevert,
  } = useHearingEvaluationStore()

  const {
    setApplyForReset,
    applyForReset,
    selectInputs,
    handleApplySoundMatchFromHistory,
  } = useConfigurationStore()

  const { isNewConfig } = useMobileDevicesStore()

  const [anchorEl, setAnchorEl] = useState(null)
  const [tempIndex, setTempIndex] = useState(null)

  useEffect(() => {
    setTempIndex(activeHearingAssessmentIndex)
  }, [activeHearingAssessmentIndex])

  useEffect(() => {
    if (tempIndex !== null) {
      handleApplySoundMatchFromHistory()
    }
  }, [tempIndex])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const applyConfiguration = (index) => {
    let tempDeviceConfiguration = mapKeysToCamelCase(
      DEFAULT_CONFIG_FACTORY().device_configuration
    )

    if (index === "reset") {
      if (isNewSoundMatch) {
        removeHearingEvaluation("manual")
        setIsNewSoundMatch(false)
      }
      setPreviewHearingAssessmentLeftBisgaardIndex(null)
      setPreviewHearingAssessmentRightBisgaardIndex(null)
      setTempHearingAssessmentLeftBisgaardIndex(null)
      setTempHearingAssessmentRightBisgaardIndex(null)
      setPreviewIndex(null)
      setTempIndex(null)
      setApplyForReset(true)
      isNewConfig
        ? handleSelectInputs(selectInputs, tempDeviceConfiguration, true)
        : handleSelectInputsOld(selectInputs, tempDeviceConfiguration, true)
      handleClose()
    } else {
      setApplyForReset(false)
      setEnabledConfigurationOfIndex(index)
      setTempIndex(index)
      handleApplySoundMatchFromHistory()
      handleClose()
    }
  }

  const { showHearingAssessmentModal } = useUiStore()
  const { newHearingAssessment } = useHearingEvaluationStore()

  const applyHEPreview = (index) => {
    setPreviewIndex(index)
    if (showHearingAssessmentModal) {
      const ha = hearingEvaluationHistoryList[index]
      const newHA = { ...newHearingAssessment }
      newHA.leftBisgaardIndex = ha.leftBisgaardIndex
      newHA.rightBisgaardIndex = ha.rightBisgaardIndex
      newHA.left500HzThreshold = ha.left500HzThreshold
      newHA.left1000HzThreshold = ha.left1000HzThreshold
      newHA.left2000HzThreshold = ha.left2000HzThreshold
      newHA.left3000HzThreshold = ha.left3000HzThreshold
      newHA.left4000HzThreshold = ha.left4000HzThreshold
      newHA.right500HzThreshold = ha.right500HzThreshold
      newHA.right1000HzThreshold = ha.right1000HzThreshold
      newHA.right2000HzThreshold = ha.right2000HzThreshold
      newHA.right3000HzThreshold = ha.right3000HzThreshold
      newHA.right4000HzThreshold = ha.right4000HzThreshold

      setNewHearingAssessment(newHA)
    } else {
      setPreviewHearingAssessmentLeftBisgaardIndex(
        hearingEvaluationHistoryList[index].leftBisgaardIndex
      )
      setPreviewHearingAssessmentRightBisgaardIndex(
        hearingEvaluationHistoryList[index].rightBisgaardIndex
      )
    }
  }

  return (
    <ConfigurationHistoriesWrapper>
      {!!hearingEvaluationHistoryList.length &&
        hearingEvaluationHistoryList.map((hearingEvaluation, index) => (
          <HistoryConfigItem
            key={index}
            onClick={() => {
              applyHEPreview(index)
            }}
          >
            <DateWrapper active={!isShowingRevert && hearingEvaluation.active}>
              {format(
                new Date(hearingEvaluation?.completeDate),
                "d LLL yyyy hh:mm aaa"
              )}
              {hearingEvaluation.manuallyCreated && (
                <ManualWrapper>
                  <ManualIcon />
                </ManualWrapper>
              )}
            </DateWrapper>

            {!isShowingRevert &&
              !showHearingAssessmentModal &&
              hearingEvaluation.active && (
                <ActiveWrapper>Enabled</ActiveWrapper>
              )}

            {!showHearingAssessmentModal && (
              <ControlButtons>
                <ActionWrapper
                  id={`history-configuration-${index}`}
                  onClick={handleClick}
                />
                <StyledMenu
                  id={`history-configuration-${index}`}
                  anchorEl={anchorEl}
                  open={Boolean(
                    anchorEl && anchorEl.id === `history-configuration-${index}`
                  )}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      applyConfiguration(index)
                    }}
                  >
                    Set as enabled
                  </MenuItem>
                </StyledMenu>
              </ControlButtons>
            )}
          </HistoryConfigItem>
        ))}
      {!showHearingAssessmentModal && (
        <HistoryConfigItem>
          <DateWrapper active={isShowingRevert}>
            {" "}
            Revert to Factory Presets
          </DateWrapper>
          {isShowingRevert && <ActiveWrapper>Enabled</ActiveWrapper>}
          <ControlButtons>
            <ActionWrapper
              id={`history-configuration-reset`}
              onClick={handleClick}
            />
            <StyledMenu
              id={`history-configuration-reset`}
              anchorEl={anchorEl}
              open={Boolean(
                anchorEl && anchorEl.id === `history-configuration-reset`
              )}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  applyConfiguration("reset")
                }}
              >
                Apply to Revert
              </MenuItem>
            </StyledMenu>
          </ControlButtons>
        </HistoryConfigItem>
      )}
    </ConfigurationHistoriesWrapper>
  )
}

export default observer(HearingEvaluationHistory)
