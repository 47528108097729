import React, { useEffect } from "react"
import _ from "lodash"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import colors from "styles/colors"
import {
  useConfigurationStore,
  useHearingEvaluationStore,
  useLogsStore,
  useUiStore,
  useMobileDevicesStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import HearingProfileTile from "./hearing_profile_tile/hearing_profile_tile"
import HearingEvaluation from "./hearing_evaluation/hearing_evaluation"
import PendingConfigurationButton from "./pending_configuration_button/pending_configuration_button"
import { toJS } from "mobx"
import {
  handleSelectInputs,
  prepareDeviceConfigurationSchema,
  validateOnInput,
} from "./programs_helpers"
import "./hearing_profiles_dnd/grid_style.css"
import { SortableList } from "./hearing_profiles_dnd/hearing_profiles_dnd"
import Loading from "components/loading/loading"
import {
  mapKeysToCamelCase,
  resetLogHistoryHighlights,
} from "../../../utils/helpers"
import { format } from "date-fns"
import NewHearingAssessmentDialog from "components/dialogs/new_hearing_assessent_dialog/new_hearing_assessment_dialog"

const Wrapper = styled.div`
  display: flex;
  width: 92%;
  margin: 0 auto;
  padding: 10px 0;
  ${breakpoint("mobile", "tablet")`
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  `}
  ${breakpoint("mobile")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : null}
    flex-direction: row;
  `}
  ${breakpoint("tablet")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : null}
    flex-direction: row;
  `}
  ${breakpoint("desktop")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : `
        overflow-y: hidden;
        overflow-x: hidden;
    `}
    flex-direction: row;
  `}
`

const ButtonsBar = styled.div`
  width: 92%;
  height: 40px;
  margin: 0 auto;
  padding: 16px 0 16px 0;
`

const NewConfigurationButton = styled.button`
  font-family: Inter;
  font-weight: 800;
  height: 32px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.grey : colors.orange};
  border: none;
  float: right;
  &:hover {
    cursor: pointer;
  }
  width: 190px;

  ${breakpoint("mobile", "tablet")`
     width: 50%;
  `}
`

const CancelButton = styled.button`
  font-family: Inter;
  font-weight: 800;
  height: 32px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: none;
  float: right;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
  width: 190px;

  ${breakpoint("mobile", "tablet")`
     margin-right: 0;
     width: 50%;
  `}
`

const LeftWrapper = styled.div`
  flex: 1.3;
  ${breakpoint("mobile", "tablet")`
    margin-bottom: 16px
  `}
`

const RightWrapper = styled.div`
  flex: 2;
`

const LogConfigurationTitle = styled.div`
  height: 32px;
  line-height: 32px;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-left: 24px;
`

const LogConfigurationDate = styled.div`
  height: 32px;
  line-height: 32px;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 2%;
`

const Programs = () => {
  const {
    profilesInNewConfiguration,
    setEnvOffsetsInNewHearingAssessment,
    setEnvOffsetsInTempHearingAssessment,
    deviceConfiguration,
    isLoaded,
    sendConfiguration,
    clearHistoryDeviceConfiguration,
    historyDeviceConfiguration,
    defaultConfig,
    applyForReset,
    emptyDefaultConfig,
    newDeviceConfiguration,
    setNewDeviceConfiguration,
    isSending,
    setFavoritesForTempConfiguration,
    setPresetOffsetForTempConfiguration,
    setEnvOffsetForTempConfiguration,
    clearDeviceConfigurationValues,
    areProfilesChanged,
    isFavoriteChanged,
    areAllProgramsDisabled,
    areAllProgramsUnique,
    areFavoritesRemoved,
    checkIfBaselineIsSelectedForBoth,
    checkIfNormalProgramIsPresentWhenSoundMatchApplied,
    selectInputs,
    setIsEnabled,
    setIsEmpty,
    setFeedbackEnabled,
    setValueWithKey,
    setBaseProgram,
    setValueToInputsAndSelects,
  } = useConfigurationStore()

  const {
    clearHearingEvaluationValues,
    checkIfHEisChanged,
    isNewSoundMatch,
    clearNewHearingAssessment,
    clearPreviewHearingEvaluationValues,
    newHearingAssessment,
    tempHearingAssessment,
    setShowingRevert,
  } = useHearingEvaluationStore()

  const {
    hearingProfilesList,
    plainDeviceConfiguration,
    isHearingAssessmentResInAny,
  } = deviceConfiguration

  const {
    openConfigurationModal,
    showConfigurationModal,
    closeConfigurationModal,
    openHearingAssessmentDialog,
  } = useUiStore()

  const {
    lastLogWithStatus,
    currentLogTitle,
    currentLogDate,
    clearLogsValues,
  } = useLogsStore()

  const { openNotification } = useUiStore()

  const { isSsaEnabledDevice } = useMobileDevicesStore()

  // opening configuration from logs
  useEffect(() => {
    if (historyDeviceConfiguration !== null) {
      setTimeout(() => {
        clearDeviceConfigurationValues()
        const tempDeviceConfiguration = toJS(historyDeviceConfiguration, {
          recurseEverything: true,
        })
        setNewDeviceConfiguration(tempDeviceConfiguration)
      })
    }
  }, [historyDeviceConfiguration])

  //opening configuration by "new configuration"
  useEffect(() => {
    const tempDeviceConfiguration = toJS(plainDeviceConfiguration, {
      recurseEverything: true,
    })

    delete tempDeviceConfiguration.configurationHistories
    setNewDeviceConfiguration(tempDeviceConfiguration)
  }, [
    isLoaded,
    defaultConfig,
    plainDeviceConfiguration,
    clearHistoryDeviceConfiguration,
  ])

  //applying default configuration on "reset to defaults"
  useEffect(() => {
    let tempDeviceConfiguration = {}
    if (applyForReset) {
      const defaultConfig = emptyDefaultConfig(isSsaEnabledDevice)

      tempDeviceConfiguration = toJS(
        mapKeysToCamelCase(defaultConfig.device_configuration),
        {
          recurseEverything: true,
        }
      )
    } else {
      const config = historyDeviceConfiguration || plainDeviceConfiguration
      tempDeviceConfiguration = toJS(config, {
        recurseEverything: true,
      })
    }

    setNewDeviceConfiguration(tempDeviceConfiguration)
  }, [applyForReset])

  useEffect(() => {
    if (!isNewSoundMatch) {
      handleSelectInputs(selectInputs, newDeviceConfiguration, false)
    }
  }, [newDeviceConfiguration, isNewSoundMatch])

  useEffect(() => {
    if (
      newHearingAssessment &&
      !_.isEqual(
        newHearingAssessment.environmentalOffsets,
        profilesInNewConfiguration
      )
    ) {
      setEnvOffsetsInNewHearingAssessment()
    }

    if (
      tempHearingAssessment &&
      !_.isEqual(
        tempHearingAssessment.environmentalOffsets,
        profilesInNewConfiguration
      )
    ) {
      setEnvOffsetsInTempHearingAssessment()
    }
  }, [profilesInNewConfiguration, newHearingAssessment, tempHearingAssessment])

  const newConfigurationClick = () => {
    setShowingRevert(false)
    openConfigurationModal()
  }

  const clearValues = () => {
    clearNewHearingAssessment()
    clearHearingEvaluationValues()
    clearDeviceConfigurationValues()
    clearLogsValues()
  }

  const cancelAndCloseModal = () => {
    resetLogHistoryHighlights()

    if (isNewSoundMatch) {
      openHearingAssessmentDialog()
    } else {
      clearValues()
      closeConfigurationModal()
    }
  }

  const onChange = async (e) => {
    const target = e.target
    const type = target.type
    const side = target.name.split("-")[2] // side of the value (left, right)
    const index = target.name.split("-")[1] // index of hearingProfile (program) e.g., "0" = P1, "1" = P2, etc.
    const key = target.name.split("-")[0] // key is the properties that is changing
    const value = target.value

    switch (key) {
      case "environmentalOffset":
        //presets 1,2,3,4
        if (
          ["PROGRAM_A", "PROGRAM_B", "PROGRAM_C", "PROGRAM_D"].includes(value)
        ) {
          return setPresetOffsetForTempConfiguration(key, value, index)
        } else {
          return setEnvOffsetForTempConfiguration(key, value, index)
        }
        break
      case "isRightFeedbackEnabled":
      case "isLeftFeedbackEnabled":
        return setFeedbackEnabled(key, index, side, value)
      case "noiseReduction":
        return setValueWithKey(key, index, value)
      case "isEnabled":
        return setIsEnabled(key, index)
      case "isEmpty":
        return setIsEmpty(index)
      case "favouriteLeftSlot":
      case "favouriteRightSlot":
        return setFavoritesForTempConfiguration(key, value, index)
      case "baseProgramRight":
        return setBaseProgram(key, index, side, value)
      case "baseProgramLeft":
        return setBaseProgram(key, index, side, value)
      case "isEcaEnabled":
        return setValueWithKey(key, index, value)
      case "isSsaEnabled":
        return setValueWithKey(key, index, value)
      case "ssaValue":
        return setValueWithKey(key, index, value)
    }

    setValueToInputsAndSelects(key, index, side, type, value)
    return await validateOnInput(newDeviceConfiguration, target)
  }

  const applyNewConfiguration = async () => {
    try {
      if (checkIfNormalProgramIsPresentWhenSoundMatchApplied) {
        return openNotification(
          "You can't send a configuration without a Normal program when Sound Match results have been applied.",
          "warning"
        )
      }

      if (checkIfBaselineIsSelectedForBoth) {
        return openNotification(
          "You can't choose baselines for only one HI. Please select baseline for both HI's.",
          "warning"
        )
      }

      if (areFavoritesRemoved && !applyForReset) {
        return openNotification(
          "You can't remove favorite program. Please select another favorite program to apply.",
          "warning"
        )
      }

      if (areAllProgramsUnique.result) {
        return openNotification(
          `You can't send configuration with duplicated offsets. Please select unique offsets. (${
            areAllProgramsUnique.data || ""
          })`,
          "warning"
        )
      }

      if (areAllProgramsDisabled) {
        return openNotification(
          "At least one program must be enabled",
          "warning"
        )
      }
      if (
        areProfilesChanged ||
        checkIfHEisChanged ||
        isFavoriteChanged ||
        applyForReset
      ) {
        const validConfiguration = await prepareDeviceConfigurationSchema().validate(
          newDeviceConfiguration
        )

        clearPreviewHearingEvaluationValues()
        closeConfigurationModal()
        await sendConfiguration(validConfiguration)
        clearValues()
      } else {
        return openNotification(
          "you need to make a change before applying",
          "warning"
        )
      }
    } catch (e) {
      console.error(e)
      openNotification(`validation error in ${e.path} - ${e.errors}`, "error")
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    const checkOnWhichTileFavouriteWas = (favouriteSlot) => {
      if (
        (favouriteSlot !== null && favouriteSlot === oldIndex + 1) ||
        favouriteSlot === newIndex + 1
      ) {
        if (favouriteSlot === oldIndex + 1) {
          return newIndex + 1
        } else if (favouriteSlot === newIndex + 1) {
          return oldIndex + 1
        }
      } else {
        return favouriteSlot
      }
    }

    const tempNewDeviceConfiguration = toJS(newDeviceConfiguration)
    let tempArray = tempNewDeviceConfiguration.hearingProfiles.slice()

    if (
      tempArray[oldIndex].isHearingAssessmentRes === true ||
      tempArray[newIndex].isHearingAssessmentRes === true
    ) {
      return
    }

    const tempValue = tempArray[oldIndex]
    tempArray[oldIndex] = tempArray[newIndex]
    tempArray[newIndex] = tempValue
    tempArray.forEach((item, index) => (item.position = index + 1))
    const finalObject = {
      ...tempNewDeviceConfiguration,
      favouriteLeftSlot: checkOnWhichTileFavouriteWas(
        tempNewDeviceConfiguration.favouriteLeftSlot
      ),
      favouriteRightSlot: checkOnWhichTileFavouriteWas(
        tempNewDeviceConfiguration.favouriteRightSlot
      ),
      hearingProfiles: tempArray,
    }
    setNewDeviceConfiguration(finalObject)
  }

  return (
    <>
      <NewHearingAssessmentDialog clearValues={clearValues} />
      <ButtonsBar>
        <NewConfigurationButton
          disabled={isSending}
          onClick={
            showConfigurationModal
              ? applyNewConfiguration
              : newConfigurationClick
          }
        >
          {isSending ? (
            <Loading size={12} />
          ) : showConfigurationModal ? (
            "apply"
          ) : (
            "new configuration"
          )}
        </NewConfigurationButton>
        {showConfigurationModal && (
          <CancelButton onClick={cancelAndCloseModal}>Cancel</CancelButton>
        )}
        {!showConfigurationModal && lastLogWithStatus && (
          <PendingConfigurationButton />
        )}
        {showConfigurationModal && currentLogDate && (
          <LogConfigurationDate>
            {format(new Date(currentLogDate), "EEEE, MMMM d, yyyy h:mm a")}
          </LogConfigurationDate>
        )}
        {showConfigurationModal && currentLogTitle && (
          <LogConfigurationTitle>{currentLogTitle}</LogConfigurationTitle>
        )}
      </ButtonsBar>
      <Wrapper isModalShown={showConfigurationModal ? 1 : 0}>
        <LeftWrapper>
          <HearingEvaluation />
        </LeftWrapper>
        <RightWrapper>
          <Grid container spacing={2}>
            {!showConfigurationModal ? (
              hearingProfilesList.map((hearingProfile, index) => (
                <HearingProfileTile
                  key={`hearing-profile-tile-${index}`}
                  hearingProfile={hearingProfile}
                  isHearingAssessmentResInAny={isHearingAssessmentResInAny}
                  index={index}
                />
              ))
            ) : (
              <SortableList
                useDragHandle
                items={newDeviceConfiguration}
                onSortEnd={onSortEnd}
                isLoaded={isLoaded}
                onChange={onChange}
                isHearingAssessmentResInAny={isHearingAssessmentResInAny}
                axis="xy"
                helperClass="sortableHelper"
              />
            )}
          </Grid>
        </RightWrapper>
      </Wrapper>
    </>
  )
}

export default observer(Programs)
