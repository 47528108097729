import { SortableContainer, SortableElement } from "react-sortable-hoc"
import HearingProfileTile from "../hearing_profile_tile/hearing_profile_tile"
import { Grid } from "@material-ui/core"
import React from "react"

const SortableItem = SortableElement(
  ({
    value,
    hearingProfileIndex,
    newFavouriteLeftSlot,
    newFavouriteRightSlot,
    onChange,
    isHearingAssessmentResInAny,
  }) => {
    return (
      <HearingProfileTile
        key={value?.id || `hearing-profile-tile-${hearingProfileIndex}`} 
        onChange={onChange}
        newFavouriteLeftSlot={newFavouriteLeftSlot}
        newFavouriteRightSlot={newFavouriteRightSlot}
        hearingProfile={value}
        index={hearingProfileIndex}
        isHearingAssessmentResInAny={isHearingAssessmentResInAny}
      />
    )
  }
)

const SortableList = SortableContainer(
  ({ items, onChange, isHearingAssessmentResInAny }) => {
    return (
      <Grid container spacing={2}>
        {items.hearingProfiles.map((value, index) => {
          return (
            <SortableItem
              index={index}
              key={value?.id || `sortable-item-${index}`}
              newFavouriteLeftSlot={items.favouriteLeftSlot}
              newFavouriteRightSlot={items.favouriteRightSlot}
              isHearingAssessmentResInAny={isHearingAssessmentResInAny}
              hearingProfileIndex={index}
              value={value}
              onChange={onChange}
            />
          )
        })}
      </Grid>
    )
  }
)

export { SortableList }
