import React, { useState } from "react"
import styled from "styled-components"

const MAX_NOTES_CHARACTER_COUNT = 255
const EditableNotesWrapper = styled.div`
  color: black;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 150px;
  border-color: lightgrey;
  background-color: white;
  resize: vertical;
`

const CharactersCounterWrapper = styled.div`
  font-size: 12px;
  color: grey;
  display: flex;
  justify-content: flex-end;
`

const ButtonsAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonsWrapper = styled.span`
  margin: 5px;
`

const Button = styled.button`
  width: 130px;
  border-radius: 40px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  &.cancel {
    background-color: white;
    color: #0A0A0A;
    border: 1px solid #0A0A0A;
  }
  &.save {
    background-color: #CF4520;
    color: white;
    border: none;
  }
`

const EditableNotes = ({ id, initialText, onCancel, onSave }) => {
  const [text, setText] = useState(initialText || "")

  const handleTextChange = (e) => {
    if (e.target.value.length <= MAX_NOTES_CHARACTER_COUNT) {
      setText(e.target.value)
    }
  }

  const handleCancel = () => {
    setText(initialText)
    onCancel();
  }

  const handleSave = () => {
    onSave(id, text)
  }

  return (
    <EditableNotesWrapper>
      <Textarea
        value={text}
        onChange={handleTextChange}
      />
      <CharactersCounterWrapper>{text.length}/{MAX_NOTES_CHARACTER_COUNT}</CharactersCounterWrapper>
      <ButtonsAreaWrapper>
        <ButtonsWrapper>
          <Button className="cancel" onClick={handleCancel}>Cancel</Button>
        </ButtonsWrapper>
        <ButtonsWrapper>
          <Button className="save" onClick={handleSave}>Save</Button>
        </ButtonsWrapper>
      </ButtonsAreaWrapper>
    </EditableNotesWrapper>
  )
}

export default EditableNotes
// export default observer(EditableNotes)
