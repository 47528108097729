import React from "react"
import { Grid } from "@material-ui/core"
import { useMobileDevicesStore } from "hooks/stores"
import { observer } from "mobx-react"
import { StyledPaper } from "./components/hearing_profile_tile_styles"
import HearingProfileTileEmpty from "./components/hearing_profile_tile_empty"
import HearingProfileTileDisabled from "./components/hearing_profile_tile_disabled"
import HearingProfileTileHeader from "./components/hearing_profile_tile_header"
import HearingProfileTileInputsNewConfig from "./components/new_config/hearing_profile_tile_inputs_new_config"
import HearingProfileTileInputsOldConfig from "./components/old_config/hearing_profile_tile_inputs_old_config"
import ReactTooltip from "react-tooltip"

const HearingProfileTile = ({
  hearingProfile,
  newFavouriteLeftSlot,
  newFavouriteRightSlot,
  index,
  onChange,
}) => {
  const { isEmpty, isEnabled } = hearingProfile

  const { isNewConfig } = useMobileDevicesStore()

  if (isEmpty) {
    return (
      <HearingProfileTileEmpty
        key={`hearing-profile-empty-${hearingProfile.id || index}`} 
        isEmpty={isEmpty}
        isEnabled={isEnabled}
        index={index}
        onChange={onChange}
      />
    )
  }

  if (!isEnabled) {
    return (
      <HearingProfileTileDisabled
        key={`hearing-profile-disabled-${hearingProfile.id || index}`} 
        isEnabled={isEnabled}
        index={index}
        onChange={onChange}
      />
    )
  }

  return [
    <Grid item xs={12} md={6} key={`hearing-profile-grid-${hearingProfile.id || index}`}>
      <StyledPaper>
        <form>
          <HearingProfileTileHeader
            key={`hearing-profile-header-${hearingProfile.id || index}`}
            hearingProfile={hearingProfile}
            newFavouriteLeftSlot={newFavouriteLeftSlot}
            newFavouriteRightSlot={newFavouriteRightSlot}
            index={index}
            onChange={onChange}
          />
          {isNewConfig ? (
            <HearingProfileTileInputsNewConfig
              key={`hearing-profile-new-config-${hearingProfile.id || index}`}
              hearingProfile={hearingProfile}
              index={index}
              onChange={onChange}
            />
          ) : (
            <HearingProfileTileInputsOldConfig
              key={`hearing-profile-old-config-${hearingProfile.id || index}`}
              hearingProfile={hearingProfile}
              index={index}
              onChange={onChange}
            />
          )}
        </form>
      </StyledPaper>
    </Grid>,
    <ReactTooltip
      key={`react-tooltip-${index}`}
      backgroundColor="#F2BF45"
      textColor="#333333"
      place="right"
    />,
  ]
}

export default observer(HearingProfileTile)
